var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card-title',[_c('h2',[_vm._v("Платежи")])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-dialog',{attrs:{"width":"295"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Выбор даты","prepend-icon":"mdi-calendar","readonly":""},on:{"click":function($event){_vm.filters.dateDialog = true}},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.filters.dateDialog),callback:function ($$v) {_vm.$set(_vm.filters, "dateDialog", $$v)},expression:"filters.dateDialog"}},[_c('v-card',{staticClass:"p-0 m-0"},[_c('v-card-text',{staticClass:"pa-0 ma-0"},[_c('v-date-picker',{staticClass:"w-full",attrs:{"range":""},model:{value:(_vm.filters.dates),callback:function ($$v) {_vm.$set(_vm.filters, "dates", $$v)},expression:"filters.dates"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.filters.dates = []; _vm.filters.dateDialog = false}}},[_vm._v(" Очистить ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.filters.dateDialog = false}}},[_vm._v(" Ок ")])],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 mx-0 p-0",attrs:{"loading":_vm.loading,"aria-label":"Платежи","headers":_vm.headers,"items":_vm.orders,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.payed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sum - item.fullDiscount)+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateformat(new Date(item.createdAt), "yyyy-mm-dd H:MM:ss"))+" ")]}}])}),_c('div',{staticClass:"text-center pt-2"},[(_vm.total)?_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }