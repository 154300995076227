<template>
  <v-container fluid>
    <v-card-title>
      <h2>Платежи</h2>
    </v-card-title>
    <v-row>
      <v-col
          cols="12"
          sm="4"
      >
        <v-dialog
            v-model="filters.dateDialog"
            width="295"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateRangeText"
                label="Выбор даты"
                prepend-icon="mdi-calendar"
                @click='filters.dateDialog = true'
                v-bind="attrs"
                v-on="on"
                readonly
            ></v-text-field>

          </template>

          <v-card class="p-0 m-0">
            <!--            <v-card-title class="text-h5">
                          Выход
                        </v-card-title>-->

            <v-card-text class="pa-0 ma-0">
              <v-date-picker
                  class="w-full"
                  v-model="filters.dates"
                  range
              ></v-date-picker>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>

              <v-btn
                  text
                  @click="filters.dates = []; filters.dateDialog = false"
              >
                Очистить
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                  text
                  @click="filters.dateDialog = false"
              >
                Ок
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-col>
    </v-row>
    <v-data-table
        :loading="loading"
        aria-label="Платежи"
        :headers="headers"
        :items="orders"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        class="elevation-1 mx-0 p-0"
    >
      <template v-slot:item.payed="{ item }">
        {{ item.sum - item.fullDiscount }}
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ dateformat(new Date(item.createdAt), "yyyy-mm-dd H:MM:ss") }}
      </template>

      <!--      <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-container fluid class="flex align-bottom">
                  <p><b>Скидка грн. - </b>{{ item.discount }}</p>
                  <p><b>Комиссия  грн. - </b>{{ item.dividends }}</p>
                </v-container>
              </td>
            </template>-->

    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
          v-if="total"
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>

import dateformat from 'dateformat'

export default {
  name: "OrdersList",
  data: () => ({
    singleExpand: null,
    expanded: [],
    filters: {
      dates: [],
      dateDialog: false,
    },
    headers: [
      {text: 'ID', value: 'id', sortable: false,},
      {text: 'Бизнес', value: 'terminal.business.name', sortable: false,},
      {text: 'Бокс', value: 'terminal.name', sortable: false,},
      {text: 'Сумма грн.', value: 'sum', sortable: false,},
      {text: 'Скидка грн.', value: 'discount', sortable: false,},
      {text: 'Комиссия грн.', value: 'dividends', sortable: false,},
      {text: 'Оплачено грн.', value: "payed", sortable: false,},
      {text: 'Статус', value: "status", sortable: false,},
      {text: 'Дата', value: "createdAt", sortable: false,},
      /*      { text: '', value: 'data-table-expand' },*/
    ],
    orders: [],
    itemsPerPage: 20,
    page: 1,
    total: 0,
    loading: true
  }),
  created() {
    this.loadPage(1)
  },
  methods: {
    dateformat,
    async loadPage(page) {
      this.loading = true;
      let startDate = this.filters.dates[0] ? this.filters.dates[0] + "T00:00:00.000Z" : '';
      let endDate = this.filters.dates[1] ? this.filters.dates[1] + "T23:59:59.000Z" : '';
      this.$store.dispatch("loadOrders",
          {
            page,
            pageLimit: this.itemsPerPage,
            filters: {startDate, endDate, status: 'SUCCESS'}
          },)
          .then(({orders, total, page}) => {
            console.log({orders, total, page});
            if (this.page !== page) return;

            this.orders = orders;
            this.total = total;
            this.loading = false
          })
    }
  },
  watch: {
    page(oldPage, newPage) {
      if (oldPage === newPage) return;
      this.loadPage(oldPage)
    },
    'filters.dates'(oldVal, newVal) {
      if (oldVal === newVal) return;
      this.filters.dates = this.filters.dates.sort()
      this.loadPage(this.page)
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.itemsPerPage)
    },
    dateRangeText() {
      return this.filters.dates.join(' ~ ')
    },
  }
}
</script>

<style scoped>

</style>
